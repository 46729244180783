<template>
  <!-- <el-dialog
    title="新增计划"
    :visible.sync="dialogVisible"
    width="300px"
    :before-close="handleClose"
    :show-close="false"
    :close-on-click-modal="false"
    custom-class="layerDailog"
  > -->
  <div class="dialogBox" v-if="showForm">
    <div class="gaine">
      <div class="fialogTitle">片区</div>
      <!-- <i class="el-icon-close modality" @click="handleClose()"></i> -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="formBox"
      >
        <el-form-item label="区域名称" prop="networkName">
          <el-input
            v-model="form.networkName"
            maxlength="30"
            show-word-limit
            placeholder="请输入区域名称"
            class="widthes"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
           <el-input-number v-model="form.sort" ></el-input-number>
        </el-form-item>
        <el-form-item label="颜色" prop="sort">
           <el-color-picker v-model="form.color"></el-color-picker>
        </el-form-item>
        <el-row style="text-align: center; margin-top: 53px">
          <el-button type="primary" @click="save" class="buttones"
            >确 认</el-button
          >
          <el-button class="buttones" @click="handleClose()"
            >取消</el-button
          >
        </el-row>
      </el-form>
    </div>
  </div>
  <!-- </el-dialog> -->
</template>
<script>
import { saveMake, delMake, networkDetail } from "@/apis/commonType";
import { getUserList } from "@/RequestPort/maintenance/task";
import { ChangePlan } from "@/RequestPort/Inspection/inspePlan";
import { queryFeatrue } from '@/apis/geo'
export default {
  components: {},
  props:{
    showForm:{
      type:Boolean,
      default:false
    },
    form:{
      type:Object,
      default: function(){
        return {
          sort:1
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      markType: "1",
      rules: {
        networkName: [
          { required: true, message: "请输入区域名称", trigger: "change" },
        ],
        executorPeopleId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      // 巡线员
      excutorDownArray: [],
      BoxselectionStart:false
    };
  },
  destroyed() {
    this.$eventBus.$off("mapRegion");
    this.$eventBus.$off("transferAddForm");
    this.$eventBus.$off("Boxselection");
    this.BoxselectionStart = false
  },
  mounted() {
    if(!this.form.sort) this.form.sort = 0
    this.$eventBus.$on("mapRegion", (data) => {
      this.$parent.form.location = data;
    });
    this.$eventBus.$on('Boxselection',(data) => {
      this.BoxselectionStart = data ? true : false
    })
    // 提交获取addform坐标
    this.$eventBus.$on("transferAddForm", (data) => {
      
      this.form.location = this.setPolygon(data);
    });
    /**
     * 获取巡线员
     */
    // getUserList({type:2}).then((e) => {
    //   this.excutorDownArray = e.data;
    // });
    
    // 判断是否有id
    // if (this.$route.query.id) {
    //   // 调取接口获取参数赋值
    //   networkDetail({ id: this.$route.query.id }).then((res) => {
    //     console.log(res);
    //   });
    //   // 判断是否是查询
    //   if (this.$route.query.inquire) {
    //     // 是查询的话
    //     this.$parent.showForm = false;
    //   }
    // }
  },
  methods: {
    initialize(e) {
      
    //   this.$parent.form = e.item;
      this.$parent.showForm = true;
      this.$eventBus.$emit("vectorEdit", {
        data: { location: e.item.line,color:'#1B9E77' },
        type: "patrol_plan",
      });
      if(this.BoxselectionStart){//添加的时候调用
        let bbox=this.$parent.map.getBounds()
       this.getLayerId(e.item.line,bbox)
      }
      
    },
    getLayerId(line,bounds){
      let north = bounds.northEast
      let south = bounds.southWest
      let bbox=south.lng+','+south.lat+','+north.lng+','+north.lat
      let pos = []
      line.forEach(element => {
        pos.push(element.lng + ' ' + element.lat)
      })
      let start=line[0]
      let startStr=start.lng + ' ' + start.lat
      pos.push(startStr)
       let posStr = pos.join(',')
       this.params = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetMap',
        FORMAT: 'image/png',
        EPSG:4326,
        format: 'geojson',
        LAYERS: 'linqing:lineWalking', 
        WIDTH:768,
        HEIGHT:330,
        outputFormat: 'application/json',
        BBOX:bbox,
        STYLES: '',
        CQL_FILTER: ' INTERSECTS(location,POLYGON((' + posStr + ')))'
      }
      
      queryFeatrue(this.params).then(res => {
        let pitchList = []
        res.features.forEach(el => {
          let index = pitchList.findIndex(item => item == el.properties.coverage)
          if(index < 0) {
            pitchList.push(el.properties.coverage)
          }
        })
        this.$eventBus.$emit("coverageNumber",pitchList.join(','))
      })

    },
    /**
     * 提交
     */
    norepeat(arr){
            let a = []
            for(var i in arr){
                if(a.indexOf(arr[i])==-1){
                    a.push(arr[i])
                }
            }
            return a
  },
    save() {
      this.$eventBus.$emit("gainAddForm");
      this.$refs.form.validate((valid) => {
        if (valid) {
          let f={
            ...this.form          
          }
          let check=''
          if(this.$parent.$parent.$parent){
            // 取图层的选择值
              check=this.$parent.$parent.$parent.checkClick()  
          }else{
              check=this.$parent.$parent.getcheck()   
          }
          let j = []
          if(check) {
            j=[...check]
          }
          if(j&&j.length>0){
            for(let i=0;i<j.length;i++){
              if(typeof(j[i]) == 'string') {
                j.splice(i,1);
                i--;
              }
            }
            j=this.norepeat(j)
            f.coverage=j.toString()
          }
          if(!f.coverage) {
            this.$message.error("请选择图层");
            return
          }
          ChangePlan(f).then((res) => {
            this.$parent.showForm= false;
            this.$parent.form={}
            this.$message.success(res.msg);
          this.handleClose()
          // if(this.$parent.$parent.$parent){
          //     this.$parent.$parent.$parent.closePDraw()   
          // }else{
          //    this.$parent.$parent.closePDraw()   
          // }
          this.$store.commit('setLayerTree', '')
          this.$eventBus.$emit("treeCheckAll");
          //  this.$router.push({ path: "/home/inspePlans" });
          this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking'});
          });
        }
      });
    },
    setPolygon(path) {
      if (path && path.length >= 3) {
        var area = AMap.GeometryUtil.ringArea(path);
        if (area <= 0) {
          
          this.$message.error("请绘制正确的片区");
          return;
        }
      } else {
      
        this.$message.error("请绘制正确的片区");
        return;
      }
      path.push(path[0]);
      let newPath = [];
      path.forEach((item, index) => {
        let nextItem = path[index + 1];
        if (nextItem) {
          if (nextItem.lng == item.lng && item.lat == nextItem.lat) {
          } else {
            newPath.push(item);
          }
        } else {
          newPath.push(item);
        }
      });

      return newPath;
    },
    handleDel() {
      let p = { uuid: this.item.uuid };
      let that = this;
      delMake(p).then((res) => {
        this.$parent.showForm = false;
        that.$message.success(res.msg);
        that.$parent.clearDraw();
      });
    },
    coloseForm(){
      this.$parent.showForm = false;
    },
    //关闭
    handleClose() { 
      this.$eventBus.$emit('coverageclose')
      this.$parent.showFormPoint= false;
      this.$parent.form= {
       sort:1
      }
      if(this.$parent.$parent.$parent){
          this.$parent.$parent.$parent.closePDraw()   
      }else{
             this.$parent.$parent.closePDraw()   
      }
      
      
    },
  },
};
</script>

<style lang="scss" scoped>
.map-row {
  position: relative;
  // 弹窗盒子
  .dialogBox {
    background: #ffffff;
    position: absolute;
    top: 30%;
    right: 2%;
    width: 420px;
    z-index: 10;
    // height: 363px;
    border-radius: 6px;
    // 内容盒子
    .gaine {
      position: relative;
      // 标题
      .fialogTitle {
        height: 60px;
        line-height: 60px;
        color: #0c235b;
        text-align: left;
        padding-left: 30px;
        font-size: 24px;
      }
      // 关闭按钮
      .modality {
        width: 19px;
        height: 19px;
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 30px;
      }
      // form盒子
      .formBox {
        margin: 0px 30px 10px;
        // 输入区域宽
        .widthes {
          width: 230px;
        }
        // 底部按钮宽
        .buttones {
          width: 160px;
        }
      }
    }
  }
}
.el-input-number{
  width: 230px;
}
</style>
