<template>
  <div>
    <div v-if="type == 'patrol_lines' || type == 'view_all_line'">
      <LineForm
        ref="formBox"
        :showForm="true"
        :polyline1="polyline1"
        @save="save"
        @closePDraw="closePDraw"
        :addForm="addForm"
      />
    </div>

    <div v-else-if="type == 'patrol_networks' || type == 'patrol_plan'">
      <networkForm
        ref="formBox"
        :showForm="true"
        :polyline1="polyline1"
        @save="save"
        @closePDraw="closePDraw"
        :form="addForm"
      />
    </div>
    <div v-else-if="type == 'patrol_equipments' || type == 'view_all_point'">
      <equipmentForm
        ref="formBox"
        :showForm="true"
        :polyline1="polyline1"
        @save="save"
        :map="$parent.map"
        @closePDraw="closePDraw"
        :addForm="addForm"
      />
    </div>
  </div>
</template>
<script>
import LineForm from "@/views/linegasMap/commonForm/LineForm";
import networkForm from "./toolInfoPlan";
import equipmentForm from "@/views/linegasMap/commonForm/equipmentForm";
import markd from "@/assets/tool/mark_b.png";
export default {
  name: "layerEdit",
  components: { LineForm, networkForm, equipmentForm },
  props: {},
  data() {
    return {
      addForm: {
        sort: 0,
        material:'',
        color:'rgba(249, 5, 5, 0.8)'
      },
      type: "",
      polyEditor: null,
      polyline1: null,
      viewType: false,
    };
  },
  computed: {},
  watch: {},
  destroyed() {
    this.$eventBus.$off("vectorEdit");
    this.$eventBus.$off("closeEdit");
    this.$eventBus.$off("vectorLineEdit");
    this.$eventBus.$off("gainAddForm");
    this.$eventBus.$off("removeDot");
    this.$eventBus.$off("typeEdit");
  },
  mounted() {
    this.$eventBus.$on("vectorEdit", (data) => {
      this.clearLayer();
      this.type = data.type;
      if (data.data) {
        let n=JSON.parse(JSON.stringify(data.data));
        if (data.type == "view_all_line") {
          if(n.bore) {
          n.bore = Number(n.bore);
          }
          if(!n.bore) {n.bore = ''}
          if(n.thickness) {
          n.thickness = Number(n.thickness);
          }
          if(!n.thickness) {n.thickness = ''}
        }
        this.addForm = n

        if (this.addForm.coverage) {
          this.$eventBus.$emit("coverageNumber", this.addForm.coverage);
        }
      }
      if (data.type == "patrol_networks") {
        this.addForm.location = this.addForm.location;
        this.vectorEdit(this.addForm.location);
      }
      // 巡线——管线
      else if (data.type == "view_all_line") {
        this.addForm.locations = this.addForm.coordinate;
        if(this.addForm.styleWidth){
        this.addForm.styleWidth = Number(this.addForm.styleWidth);
        }
        if(this.addForm.material){
        this.addForm.material = Number(this.addForm.material);
        }
        if(this.addForm.buriedType){
        this.addForm.buriedType = Number(this.addForm.buriedType);
        }
        if(this.addForm.diameter) {
          this.addForm.diameter = String(this.addForm.diameter);
        }
        
        this.addForm.coverage = Number(this.addForm.coverage);
      
        console.log(this.addForm,"this.addForm")
        if (this.addForm.location) {
          this.addForm.locations = this.addForm.location;
        }
        this.vectorEdit(this.addForm.locations);
      } else if (data.type == "patrol_lines") {
        // 管线
        this.addForm.locations = this.addForm.coordinate;
        this.vectorEdit(this.addForm.locations);
      } else if (data.type == "patrol_equipments") {
        this.addForm.locations = this.addForm.locations;
        this.vectorEdit(this.addForm.location);
      } else if (data.type == "view_all_point") {
        // 巡线设备
        this.addForm.equipmentType = Number(this.addForm.equipmentType);
        if(this.addForm.equipmentStatus){
        this.addForm.equipmentStatus = Number(this.addForm.equipmentStatus);
        }
        this.addForm.locations = this.addForm.location;
        this.vectorEdit(this.addForm.locations);
      } else if (data.type == "patrol_lines_add") {
        this.addForm.locations = this.addForm.coordinate;
        this.vectorEdit(this.addForm.locations);
      } else if (data.type == "patrol_plan") {
        this.vectorEdit(this.addForm.location);
      }
      // 打开编辑打卡点 
      else if (data.type == "patrol_clock") {
        this.$eventBus.$emit('districtDot',this.addForm)
        // this.vectorEdit(this.addForm.location);
      }
    });
    this.$eventBus.$on("closeEdit", (data) => {
      if (data && data.type) {
        this.type = data.type;
      }
      this.closeEdit();
    });
    this.$eventBus.$on("reloadEdit", (data) => {
      this.reloadEdit();
    });
    this.$eventBus.$on("typeEdit", (data) => {
      this.type = "";
    });
    this.$eventBus.$on("vectorLineEdit", (data) => {
      this.$eventBus.$emit("setLocation", this.polyline1);
    });
    this.$eventBus.$on("gainAddForm", (data) => {
      this.$eventBus.$emit("transferAddForm", this.polyline1.getPath());
    });
    // 关闭编辑的点
    this.$eventBus.$on("removeDot", (data) => {
      if (this.polyline1) {
        this.$parent.map.remove([this.polyline1]);
      }
    });
  },
  methods: {
    /* 
经纬度格式字符串转数组
@val 字符串
type Point是点，LineString是线
*/
    formatLocation(val, type) {
      let pathObj = [];
      if (type == "LineString") {
        let temp = val.split("(")[1].split(")")[0].split(",");
        temp.forEach((item) => {
          pathObj.push(item.split(" "));
        });
      } else {
        pathObj = val.split("(")[1].split(")")[0].split(" ");
      }
      return pathObj;
    },
    reloadEdit() {
      let that = this;
      that.polyEditor.close();
      that.polyEditor.setTarget(that.polyline1);
      that.polyEditor.open();
      that.$eventBus.$emit("setLength", that.polyline1);
    },
    save() {
      this.$eventBus.$emit("reloadLayer");
      this.closePDraw();
      this.$parent.$parent.getList();
    },
    closeEdit() {
      if (this.polyEditor) {
        this.closePDraw();
      }
    },
    closePDraw() {
      console.log(this.type,22222222222,this.polyline1)
      if (
        this.type == "patrol_networks" ||
        this.type == "patrol_lines" ||
        this.type == "patrol_lines_add" ||
        this.type == "view_all_line" ||
        this.type == "patrol_plan"
      ) {
        this.polyEditor.close();
        this.$parent.map.remove([this.polyline1]);
        this.polyline1 = null;
      } else if (this.type == "patrol_equipments"||this.type == "view_all_point") {
        this.$parent.map.remove([this.polyline1]);
        this.polyline1 = null;
      }
      this.type = "";
    },
    clearLayer() {
      if (this.polyEditor) {
        this.polyEditor.close();
        if (this.polyline1) {
          this.$parent.map.remove([this.polyline1]);
        }
      } else if (this.polyline1 && this.type == "patrol_equipments") {
        this.$parent.map.remove(this.polyline1);
      } else if (this.polyline1 && this.type == "view_all_point") {
        this.$parent.map.remove(this.polyline1);
      }
    },
    vectorEdit(path, add) {
      //  path=[[118.00,39.11],[118.70,39.11],[118.90,39.11]]
      if (this.type == "patrol_networks" || this.type == "patrol_plan") {
        this.polyline1 = new AMap.Polygon({
          path: path,
          strokeColor: "green",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: "#1791fc",
          zIndex: 50,
          bubble: true,
        });
      } else if (
        this.type == "patrol_lines" ||
        this.type == "patrol_lines_add" ||
        this.type == "view_all_line"
      ) {
        this.polyline1 = new AMap.Polyline({
          path: path,
          strokeColor: "#ff0000",
          strokeWeight: 8,
          strokeOpacity: 0.9,
          zIndex: 50,
          bubble: true,
        });
      } else if (
        this.type == "patrol_equipments" ||
        this.type == "view_all_point"
      ) {
        this.polyline1 = new AMap.Marker({
          icon: markd,
          position: path,
          draggable: true,
          anchor: "bottom-center",
        });
      }
      this.$parent.map.add([this.polyline1]);

      let that = this;
      if (this.type != "patrol_lines_add") {
        this.$parent.map.setFitView(
          [this.polyline1],
          false,
          [200, 200, 200, 200]
        );
      }
      if (this.type !== "patrol_equipments" && this.type !== "view_all_point") {
        this.polyEditor = new AMap.PolylineEditor(
          this.$parent.map,
          this.polyline1
        );
        this.polyEditor.setTarget(this.polyline1);
        this.polyEditor.open();
        this.oldPath = this.polyline1.getPath();
        this.polyEditor.on("adjust", function (e) {
          if (this.type == "patrol_plan") {
            that.$eventBus.$emit("setLength", that.polyline1);
          } else {
            that.$parent.xfFeatrue(
              that.polyline1,
              "lineEdit",
              that.lineId,
              that.oldPath
            );
            that.oldPath = that.polyline1.getPath();
          }
          // that.$eventBus.$emit('setLength', that.polyline1)
        });
        this.polyEditor.on("addnode", function (e) {
          if (this.type == "patrol_plan") {
            that.$eventBus.$emit("setLength", that.polyline1);
          } else {
            that.$parent.xfFeatrue(
              that.polyline1,
              "lineEdit",
              that.lineId,
              that.oldPath
            );
            that.oldPath = that.polyline1.getPath();
          }
        });
        this.polyEditor.on("removenode", function (e) {
          that.$eventBus.$emit("setLength", that.polyline1);
        });
      } else {
        this.polyline1.on("dragend", function (obj) {
          let point = obj.lnglat;
          that.addForm.locations = [point.lng, point.lat];
          var geocoder = new AMap.Geocoder();
          geocoder.getAddress(obj.lnglat, function (status, result) {
            if (result.info === "OK") {
              that.addForm.equipmentHome = result.regeocode.formattedAddress;
            }
          });
          that.$parent.xfFeatrue(that.polyline1, "eqyedit");
          // that.$parent.$parent.setLocation2(obj.lnglat)
        });
      }
    },
  },
};
</script>
